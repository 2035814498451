import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
import * as styles from './PostItem.module.scss';

function PostItem(props) {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "placeholders/journal-thumb.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1, layout: CONSTRAINED)
        }
      }
    }
  `);
  const { post } = props;

  // Image or placehoder
  const { featuredImage } = post;
  const {
    node: {
      localFile: postImage, altText,
    },
  } = featuredImage || {
    node: {
      localFile: data.placeholderImage, altText: post.title,
    },
  };

  return (
    <div className={styles.postItem}>
      <Link className={styles.itemLink} to={`${post.uri}`}>
        <div className={styles.itemThumbWrap}>
          <GatsbyImage
            imgClassName={styles.itemThumb}
            image={getImage(postImage)}
            alt={altText}
          />
        </div>
        <div className={styles.itemTitleWrap}>
          <h2 className={styles.itemTitle}>
            {post.title}
          </h2>
        </div>
      </Link>
    </div>
  );
}
PostItem.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    featuredImage: PropTypes.shape({}),
  }).isRequired,
};
export default PostItem;
