import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './PostsList.module.scss';
import PostItem from './PostItem';

function PostsList(props) {
  const { posts } = props;
  return (
    <div className={[styles.postsListOuterWrap, 'section-content'].join(' ')}>
      <div className={styles.postsListContainer}>
        <div className={styles.postsListRow}>
          {posts.map((post) => (
            <PostItem post={post} key={post.id} />
          ))}
        </div>
      </div>
    </div>
  );
}
PostsList.defaultProps = {
  posts: [],
};
PostsList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
};
export default PostsList;
