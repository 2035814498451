import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import MenuBar from '../partials/global/MenuBar';
import PageContent from '../partials/sections/PageContent';
import PostsList from '../partials/blog/PostsList';

function BlogArchive(props) {
  const {
    data: {
      blogArchivePage,
      blogPosts: {
        nodes: posts,
      },
    },
  } = props;
  const {
    template: {
      pageContent: {
        pageSection,
      },
    },
  } = blogArchivePage;
  return (
    <section className="full-height-section">
      <Seo post={blogArchivePage} />
      <MenuBar />
      <PageContent pageSections={pageSection} />
      <PostsList posts={posts} />
    </section>
  );
}

export const query = graphql`
  query ($languageCode: WpLanguageCodeEnum, $pageId: String) {
    blogArchivePage: wpPage(id: { eq: $pageId }) {
      seo {
        ...YoastPageSEOContent
      }
      id
      template {
        templateName
        ... on WpTemplate_Blog {
          templateName
          pageContent {
            pageSection {
              ... on WpTemplate_Blog_Pagecontent_PageSection_BannerSection {
                bannerContent
                fieldGroupName
                bannerSlides {
                  fieldGroupName
                  slideContent
                  slideImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
              ... on WpTemplate_Blog_Pagecontent_PageSection_Section {
                backgroundColor
                fieldGroupName
                sectionColumn {
                  ... on WpTemplate_Blog_Pagecontent_PageSection_Section_SectionColumn_ImageBlock {
                    caption
                    columnWidth
                    imageUrl
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED)
                        }
                      }
                    }
                    fieldGroupName
                  }
                  ... on WpTemplate_Blog_Pagecontent_PageSection_Section_SectionColumn_TextBlock {
                    columnWidth
                    description
                    fieldGroupName
                  }
                  ... on WpTemplate_Blog_Pagecontent_PageSection_Section_SectionColumn_EmbedBlock {
                    caption
                    columnWidth
                    embedCode
                    fieldGroupName
                  }
                }
              }
            }
            fieldGroupName
          }
        }
      }
      slug
      title
    }
    blogPosts: allWpPost(filter: {language: {code: {eq: $languageCode}}}) {
      nodes {
        id
        title
        slug
        uri
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  }
`;

BlogArchive.propTypes = {
  data: PropTypes.shape({
    blogArchivePage: PropTypes.shape({
      template: PropTypes.shape({
        pageContent: PropTypes.shape({
          pageSection: PropTypes.arrayOf(PropTypes.shape({})),
        }).isRequired,
      }).isRequired,
    }).isRequired,
    blogPosts: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};
export default BlogArchive;
